import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from "react-router-dom";

const Electrical_Mechanical_design = () => {

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return(
        <>
            <div className="col-xl-8 col-lg-8">
                <div className="content-inner-page">

                    <h2 className="column-title mrt-0">Electrical & Mechanical Designs</h2>

                    <Carousel activeIndex={index} onSelect={handleSelect} indicators = {false} nextLabel = {null} prevLabel = {null}>
                        <Carousel.Item>
                            <img className="d-block w-100" src={`${process.env.PUBLIC_URL}/ramad_server_pictures/serv_6.jpg`} alt="First slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img className="d-block w-100" src={`${process.env.PUBLIC_URL}/ramad_server_pictures/serv_6.jpg`} alt="Second slide"
                            />
                        </Carousel.Item>
                    </Carousel>

                    <div className="gap-40"></div>

                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="column-title-small">We provide innovative solutions in Electrical & Mechanical Designs for:</h3>
                            <ul className="list-arrow">
                                <li>Drawings of infrastructural electrical installation</li>
                                <li>Building electrical installation, lightning and cable tray conduits</li>
                                <li>Earthing layouts, drawing of power supply</li>
                                <li>Data systems, multimedia, CCTV, antenna, fire alarm system, electrical load calculations and technical specification</li>
                                <li>Drawings of sanitary installation, potable and waste water installations</li>
                                <li>HVAC installations, approval sheet, technical specs and HVAC load calculations</li>
                            </ul>
                        </div>
                    </div>

                    <div className="gap-40"></div>

                    <div className="call-to-action classic">
                        <div className="row align-items-center">
                            <div className="col-md-8 text-center text-md-left">
                                <div className="call-to-action-text">
                                    <h3 className="action-title">Let's get started with your projects.</h3>
                                </div>
                            </div>
                            <div className="col-md-4 text-center text-md-right mt-3 mt-md-0">
                                <div className="call-to-action-btn">
                                    <Link to="/contact" className = "btn btn-primary px-4">Contact Us <i className='fas fa-caret-right ml-3'></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default Electrical_Mechanical_design;