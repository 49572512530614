import Footer from './Footer';
import Architectural_service from './services/Architectural_service';
import Structural_engineering from './services/Structural_engineering';
import Infrastructural_design from './services/Infrastructural_design';
import Electrical_Mechanical_design from './services/Electrical_Mechanical_design';
import Construction_services from './services/Construction_services';
import Project_management from './services/Project_management';

import { useEffect, useState } from "react";
import { landmark } from './landmark';

const Services = () => {

    const [activeNav, setActiveNav] = useState(1);
    // 1 - Architectural Design
    // 2 - Structural Engineering
    // 3 - Infrastructural Designs
    // 4 - Electrical & Mechanical Designs
    // 5 - Construction Services
    // 6 - Project Management

    const myStyle = {
        backgroundImage: `url(${process.env.PUBLIC_URL}/ramad_server_pictures/service_banner.jpg)`
    };

    const changeActiveNav = (event, val) => {
        event.preventDefault();
        setActiveNav(val);
    }

    useEffect(() => {
        landmark()
    })

    return(
        <>
            <div id="banner-area" className="banner-area" style={myStyle}>
                <div className="banner-text">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-12">
                            <div className="banner-heading">
                                <h1 className="banner-title">Services</h1>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb justify-content-center">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">About Us</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>

            <section id="main-container" className="main-container">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4">
                            <div className="sidebar sidebar-left">
                                <div className="widget">
                                    <h3 className="widget-title">Expertise Areas</h3>
                                    <ul className="nav service-menu">
                                        <li className={(activeNav === 1) ? 'active': ''}>
                                            <a href="#" onClick = {(event) => changeActiveNav(event, 1)}>Architectural Designs</a>
                                        </li>
                                        <li className={(activeNav === 2) ? 'active': ''}>
                                            <a href="#" onClick = {(event) => changeActiveNav(event, 2)}>Structural Engineering</a>
                                        </li>
                                        <li className={(activeNav === 3) ? 'active': ''}>
                                            <a href="#" onClick = {(event) => changeActiveNav(event, 3)}>Infrastructural Designs</a>
                                        </li>
                                        <li className={(activeNav === 4) ? 'active': ''}>
                                            <a href="#" onClick = {(event) => changeActiveNav(event, 4)}>Electrical & Mechanical Designs</a>
                                        </li>
                                        <li className={(activeNav === 5) ? 'active': ''}>
                                            <a href="#" onClick = {(event) => changeActiveNav(event, 5)}>Construction Services</a>
                                        </li>
                                        <li className={(activeNav === 6) ? 'active': ''}>
                                            <a href="#" onClick = {(event) => changeActiveNav(event, 6)}>Project Management</a>
                                        </li>
                                    </ul>
                                </div>

                                <div className="widget">
                                    <div id="testimonial-slide" className="testimonial-slide">
                                        <div className="item">
                                            <div className="quote-item quote-border">
                                                <div className="quote-text-border">
                                                    <small>
                                                        RAMAD outclassed other service competitors in quality of job delivery and satisfaction beyond what we expected.
                                                    </small>
                                                </div>

                                                <div className="quote-item-footer text-center">
                                                    <div className="quote-item-info">
                                                        <h3 className="quote-author">Engr. Saffi Shiyanbade</h3>
                                                        <span className="quote-subtext">CEO Trelisa Engineering Projects</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                        {(activeNav === 1) &&
                            <Architectural_service/>
                        }

                        {(activeNav === 2) &&
                            <Structural_engineering/>
                        }

                        {(activeNav === 3) &&
                            <Infrastructural_design/>
                        }

                        {(activeNav === 4) &&
                            <Electrical_Mechanical_design/>
                        }

                        {(activeNav === 5) &&
                            <Construction_services/>
                        }

                        {(activeNav === 6) &&
                            <Project_management/>
                        }

                    </div>
                </div>
            </section>

            <Footer/>
        </>
    )
}

export default Services;