import Footer from './Footer';
import React, { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from "react-router-dom";
import { landmark } from './landmark';

function About() {

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    const myStyle = {
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/banner/about_us_.jpg)`
    };

    const overlay = {
        width: '100%',
        background: 'rgba(0, 0, 0, 0.6)',
        position: 'absolute'
    }

    useEffect(() => {
        landmark()
    })

    return(
        <>
            <div id="banner-area" className="banner-area" style={myStyle}>
                <div style = {{...overlay, height: '100%'}}></div>
                <div className="banner-text">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-12">
                            <div className="banner-heading">
                                <h1 className="banner-title">About</h1>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb justify-content-center">
                                    <li className="breadcrumb-item"><a href="./home">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">About Us</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>

            <section id="main-container" className="main-container">
                <div className="container">
                    <div className="row py-5">
                        <div className="col-12">
                            <div className="content-inner-page">

                            <h2 className="column-title mrt-0">RAMAD Consults Ltd.</h2>

                            <div className="row">
                                <div className="col-md-12">
                                    <blockquote>
                                        <p>
                                        Ramad Consultants Ltd. (RCL) is a company formed by a group of young, experienced, dynamic and excellence-driven civil/infrastructural engineers, structural engineers as well as mechanical and electrical engineers who came together to provide highly innovative and cost effective engineering solutions. We offer services in designs and project management in the area of Architecture, Infrastructure, Mechanical & Electrical Engineering innovations.
                                        </p>
                                    </blockquote>
                                    <p>We are a full service, civil/engineering and consultancy company duly registered with the Corporate Affairs Commission, Nigeria with registration number <mark>RC 1668021</mark>.</p>
                                </div>
                            </div>

                            <div className="gap-40"></div>

                            <div className="row justify-content-center mb-3">
                                <div className="col-sm-12 col-md-8">
                                    <Carousel fade interval={3000} activeIndex={index} onSelect={handleSelect} indicators = {false} nextLabel = {null} prevLabel = {null}>
                                        <Carousel.Item>
                                            <img className="d-block w-100" src={`${process.env.PUBLIC_URL}/assets/images/banner/police_station.jpg`} alt="Second slide"/>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img className="d-block w-100" src={`${process.env.PUBLIC_URL}/assets/images/banner/police_station_2.jpg`} alt="Third slide"/>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img className="d-block w-100" src={`${process.env.PUBLIC_URL}/assets/images/banner/architect_lady.jpg`} alt="First slide"/>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img className="d-block w-100" src={`${process.env.PUBLIC_URL}/assets/images/banner/team_engineer.jpg`} alt="Fourth slide"/>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img className="d-block w-100" src={`${process.env.PUBLIC_URL}/assets/images/banner/5.jpg`} alt="Fifth slide"/>
                                        </Carousel.Item>
                                    </Carousel>

                                </div>
                            </div>

                            <div className="gap-40"></div>

                            <div className="row">
                                <div className="col-md-6">
                                    <h3 className="column-title-small">What Makes Us Different</h3>

                                    <p>We are a goal and purpose driven company motivated by the desire to change the landscape of engineering in Nigeria, Africa and beyond. Our firm resolve is based on excellent delivery, innovations and reliability that we have cultured as a reputation known for by clients.</p>
                                    
                                    <p>
                                        <i>
                                            RAMAD Consultants Ltd. is a foremost innovative organisation that attracts talents from crucial engineering backgrounds for trust, effectiveness and service clarity.
                                        </i>
                                    </p>
                                </div>

                                <div className="col-md-6 mt-5 mt-md-0">
                                    <h3 className="column-title-small">What we stand for</h3>

                                    <div className="accordion accordion-group accordion-classic" id="construction-accordion">
                                        <div className="card">
                                            <div className="card-header p-0 bg-transparent" id="headingOne">
                                                <h2 className="mb-0">
                                                <button className="btn btn-block text-left" type="button" data-toggle="collapse"
                                                    data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    Vision
                                                </button>
                                                </h2>
                                            </div>

                                            <div id="collapseOne" className="collapse show" aria-labelledby="headingOne"
                                                data-parent="#construction-accordion">
                                                <div className="card-body">
                                                The vision of Ramad Consultants Ltd. is to set a new standard of engineering service delivery that guarantees public safety, good quality and cost effective solution through creativity, innovation and up to date design approach.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header p-0 bg-transparent" id="headingTwo">
                                                <h2 className="mb-0">
                                                <button className="btn btn-block text-left collapsed" type="button" data-toggle="collapse"
                                                    data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    MISSION
                                                </button>
                                                </h2>
                                            </div>
                                            <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo"
                                                data-parent="#construction-accordion">
                                                <div className="card-body">
                                                Ramad Consultants Ltd., aims to provide excellent and efficient civil engineering design services that would meet global standards and make a difference especially in terms of Safety, Quality and Cost built on foundations of Credibility, Integrity and Trust.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header p-0 bg-transparent" id="headingThree">
                                                <h2 className="mb-0">
                                                <button className="btn btn-block text-left collapsed" type="button" data-toggle="collapse"
                                                    data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    SERVICE SCOPE
                                                </button>
                                                </h2>
                                            </div>
                                            <div id="collapseThree" className="collapse" aria-labelledby="headingThree"
                                                data-parent="#construction-accordion">
                                                <div className="card-body">
                                                Our clients include government and non-governmental agencies, corporate organizations, real estate developers, construction companies, architectural firms, banks and private individuals. Generally our clients encompass people with a need for quality and excellence in job delivery.
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="gap-40"></div>

                            <div className="call-to-action classic">
                                <div className="row align-items-center">
                                    <div className="col-md-8 text-center text-md-left">
                                        <div className="call-to-action-text">
                                            <h3 className="action-title">Let's get started with your projects.</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-4 text-center text-md-right mt-3 mt-md-0">
                                        <div className="call-to-action-btn">
                                            <Link to="/contact" className = "btn btn-primary px-4">Contact Us <i className='fas fa-caret-right ml-3'></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </>
    )
}

export default About;