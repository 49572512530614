import Footer from './Footer';
import React, { useEffect } from 'react';
import { landmark } from './landmark';

function Gallery() {

    const myStyle = {
        backgroundImage: `url(${process.env.PUBLIC_URL}/ramad_server_pictures/gallery_banner.jpg)`
    };

    useEffect(() => {

        landmark()

    })

    return (
        <>
            <div id="banner-area" className="banner-area" style={myStyle}>
                <div className="banner-text">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="banner-heading">
                                    <h1 className="banner-title">Projects Gallery</h1>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb justify-content-center">
                                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">Gallery</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section id="news" className="news">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-12">
                            <h2 className="section-title">Works of Excellence</h2>
                            <h3 className="section-sub-title">Accomplished Projects</h3>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-lg-6 col-md-12 mb-4">
                            <div className="latest-post border">
                                <div className="latest-post-media">
                                    <div className='bg-danger p-0' style={{ height: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: `url(${process.env.PUBLIC_URL}/ramad_server_pictures/gallery/hypercity.jpg)` }}></div>
                                </div>
                                <div className="post-body p-3">
                                    <h4 className="post-title">
                                        <a href="/gallery" className="d-inline-block">Port Harcourt Hypercity Mall</a>
                                    </h4>
                                    <div className="latest-post-meta">
                                        <span className="post-item-date small mb-2 d-inline-block">
                                            <span className='badge badge-danger rounded-0 p-1'>Architecture</span>
                                        </span>
                                        <p className="post-item-date mb-0" style={{ lineHeight: '20px', textTransform: 'none !important' }}>
                                            Front View: Port Harcourt Hypercity Mall.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12 mb-4">
                            <div className="latest-post border">
                                <div className="latest-post-media">
                                    <div className='bg-danger p-0' style={{ height: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: `url(${process.env.PUBLIC_URL}/ramad_server_pictures/gallery/steel_work.jpg)` }}></div>
                                </div>
                                <div className="post-body p-3">
                                    <h4 className="post-title">
                                        <a href="/gallery" className="d-inline-block">Steel Frabrication and Installations</a>
                                    </h4>
                                    <div className="latest-post-meta">
                                        <span className="post-item-date small mb-2 d-inline-block">
                                            <span className='badge badge-danger rounded-0 p-1'>Architecture</span>
                                        </span>
                                        <p className="post-item-date mb-0" style={{ lineHeight: '20px', textTransform: 'none !important' }}>
                                            Side View: Steel Works Design, Fabrications & Installations.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12 mb-4">
                            <div className="latest-post border">
                                <div className="latest-post-media">
                                    <div className='bg-danger p-0' style={{ height: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: `url(${process.env.PUBLIC_URL}/ramad_server_pictures/gallery/port_harcourt_building.jpg)` }}></div>
                                </div>
                                <div className="post-body p-3">
                                    <h4 className="post-title">
                                        <a href="/gallery" className="d-inline-block">Port Harcourt Garden City</a>
                                    </h4>
                                    <div className="latest-post-meta">
                                        <span className="post-item-date small mb-2 d-inline-block">
                                            <span className='badge badge-danger rounded-0 p-1'>Design & Construction</span>
                                        </span>
                                        <p className="post-item-date mb-0" style={{ lineHeight: '20px', textTransform: 'none !important' }}>
                                            Outer View: Shoprite Mall, Port Harcourt Garden City.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12 mb-4">
                            <div className="latest-post border">
                                <div className="latest-post-media">
                                    <div className='bg-danger p-0' style={{ height: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: `url(${process.env.PUBLIC_URL}/ramad_server_pictures/gallery/port_harcourt_building_2.jpg)` }}></div>
                                </div>
                                <div className="post-body p-3">
                                    <h4 className="post-title">
                                        <a href="/gallery" className="d-inline-block">Port Harcourt Garden City</a>
                                    </h4>
                                    <div className="latest-post-meta">
                                        <span className="post-item-date small mb-2 d-inline-block">
                                            <span className='badge badge-danger rounded-0 p-1'>Design & Construction</span>
                                        </span>
                                        <p className="post-item-date mb-0" style={{ lineHeight: '20px', textTransform: 'none !important' }}>
                                            Inner View: Shoprite Mall, Port Harcourt Garden City.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 mb-4">
                            <div className="latest-post border">
                                <div className="latest-post-media">
                                    <div className='bg-danger p-0' style={{ height: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: `url(${process.env.PUBLIC_URL}/ramad_server_pictures/gallery/banquet_hall_3.jpg)` }}></div>
                                </div>
                                <div className="post-body p-3">
                                    <h4 className="post-title">
                                        <a href="#" className="d-inline-block">Banquet Hall Building</a>
                                    </h4>
                                    <div className="latest-post-meta">
                                        <span className="post-item-date small mb-2 d-inline-block">
                                            <span className='badge badge-danger rounded-0 p-1'>Architecture</span>
                                        </span>
                                        <p className="post-item-date mb-0" style={{ lineHeight: '20px', textTransform: 'none !important' }}>
                                            Construction: Banquet Hall @ Port-Harcourt.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 mb-4">
                            <div className="latest-post border">
                                <div className="latest-post-media">
                                    <div className='bg-danger p-0' style={{ height: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: `url(${process.env.PUBLIC_URL}/ramad_server_pictures/gallery/banquet_hall_2.jpg)` }}></div>
                                </div>
                                <div className="post-body p-3">
                                    <h4 className="post-title">
                                        <a href="#" className="d-inline-block">Banquet Hall Building</a>
                                    </h4>
                                    <div className="latest-post-meta">
                                        <span className="post-item-date small mb-2 d-inline-block">
                                            <span className='badge badge-danger rounded-0 p-1'>Design & Construction</span>
                                        </span>
                                        <p className="post-item-date mb-0" style={{ lineHeight: '20px', textTransform: 'none !important' }}>
                                            Modelling: Banquet Hall @ Port-Harcourt.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 mb-4">
                            <div className="latest-post border">
                                <div className="latest-post-media">
                                    <div className='bg-danger p-0' style={{ height: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: `url(${process.env.PUBLIC_URL}/ramad_server_pictures/gallery/banquet_hall.jpg)` }}></div>
                                </div>
                                <div className="post-body p-3">
                                    <h4 className="post-title">
                                        <a href="#" className="d-inline-block">Banquet Hall Building</a>
                                    </h4>
                                    <div className="latest-post-meta">
                                        <span className="post-item-date small mb-2 d-inline-block">
                                            <span className='badge badge-danger rounded-0 p-1'>Design & Construction</span>
                                        </span>
                                        <p className="post-item-date mb-0" style={{ lineHeight: '20px', textTransform: 'none !important' }}>
                                            Finishing: Banquet Hall @ Port-Harcourt.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12 mb-4">
                            <div className="latest-post border">
                                <div className="latest-post-media">
                                    <div className='bg-danger p-0' style={{ height: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: `url(${process.env.PUBLIC_URL}/ramad_server_pictures/gallery/banquet_hall_1.jpg)` }}></div>
                                </div>
                                <div className="post-body p-3">
                                    <h4 className="post-title">
                                        <a href="#" className="d-inline-block">Banquet Hall Building</a>
                                    </h4>
                                    <div className="latest-post-meta">
                                        <span className="post-item-date small mb-2 d-inline-block">
                                            <span className='badge badge-danger rounded-0 p-1'>Interior Finishing</span>
                                        </span>
                                        <p className="post-item-date mb-0" style={{ lineHeight: '20px', textTransform: 'none !important' }}>
                                            Interior View: Banquet Hall @ Port-Harcourt.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12 mb-4">
                            <div className="latest-post border">
                                <div className="latest-post-media">
                                    <div className='bg-danger p-0' style={{ height: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: `url(${process.env.PUBLIC_URL}/ramad_server_pictures/gallery/port_h_supermarket.jpeg)` }}></div>
                                </div>
                                <div className="post-body p-3">
                                    <h4 className="post-title">
                                        <a href="#" className="d-inline-block">Shopping Mall</a>
                                    </h4>
                                    <div className="latest-post-meta">
                                        <span className="post-item-date small mb-2 d-inline-block">
                                            <span className='badge badge-danger rounded-0 p-1'>Structuring & Architecural Design</span>
                                        </span>
                                        <p className="post-item-date mb-0" style={{ lineHeight: '20px', textTransform: 'none !important' }}>
                                            Side View: Shopping Mall.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 mb-4">
                            <div className="latest-post border">
                                <div className="latest-post-media">
                                    <div className='bg-danger p-0' style={{ height: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: `url(${process.env.PUBLIC_URL}/ramad_server_pictures/gallery/factory_building.JPG)` }}></div>
                                </div>
                                <div className="post-body p-3">
                                    <h4 className="post-title">
                                        <a href="" className="d-inline-block">Shopping Mall</a>
                                    </h4>
                                    <div className="latest-post-meta">
                                        <span className="post-item-date small mb-2 d-inline-block">
                                            <span className='badge badge-danger rounded-0 p-1'>Structuring</span>
                                        </span>
                                        <p className="post-item-date mb-0" style={{ lineHeight: '20px', textTransform: 'none !important' }}>
                                            Side View: Shopping Mall.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 mb-4">
                            <div className="latest-post border">
                                <div className="latest-post-media">
                                    <div className='bg-danger p-0' style={{ height: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: `url(${process.env.PUBLIC_URL}/ramad_server_pictures/gallery/factory_building_2.JPG)` }}></div>
                                </div>
                                <div className="post-body p-3">
                                    <h4 className="post-title">
                                        <a href="#" className="d-inline-block">Shopping Mall</a>
                                    </h4>
                                    <div className="latest-post-meta">
                                        <span className="post-item-date small mb-2 d-inline-block">
                                            <span className='badge badge-danger rounded-0 p-1'>Structuring</span>
                                        </span>
                                        <p className="post-item-date mb-0" style={{ lineHeight: '20px', textTransform: 'none !important' }}>
                                            Outer View: Shopping Mall.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </section>

            <Footer />
        </>
    )
}

export default Gallery;