import Layout from './headers/Layout';
import Home from './Home';
import About from './About';
import Services from './Services';
import Contact from './Contact';
import Gallery from './Gallery';
import Error404 from './Error404';
import { useEffect } from 'react';

import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {

  useEffect(() => {

    let targetElement = null
    const pathname = window.location.pathname;

    let all_nav_links = document.getElementsByClassName('nav-link')

    for (let i = 0; i < all_nav_links.length; i++) {
      all_nav_links[i].parentElement.classList.remove('active')
      if (pathname === all_nav_links[i].pathname) {
        targetElement = all_nav_links[i]
      }
    }

    if (targetElement != null) {
      targetElement.parentElement.classList.add('active')
    }

  })

  return (
    <>

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="services" element={<Services />} />
            <Route path="contact" element={<Contact />} />
            <Route path="gallery" element={<Gallery />} />
            <Route path="*" element={<Error404 />} />
          </Route>
        </Routes>
      </BrowserRouter>

    </>
  );
}

export default App;
