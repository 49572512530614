const landmark = () => {
    let targetElement = null
    const pathname = window.location.pathname;

    let all_nav_links = document.getElementsByClassName('nav-link')

    for (let i = 0; i < all_nav_links.length; i++) {
        all_nav_links[i].parentElement.classList.remove('active')
        if (pathname === all_nav_links[i].pathname) {
            targetElement = all_nav_links[i]
        }
    }

    if (targetElement != null) {
        targetElement.parentElement.classList.add('active')
    }
}

export {landmark}