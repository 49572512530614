import { Outlet, Link } from "react-router-dom";

function Layout() {

    return (
        <>
            <div id="top-bar" className="top-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-8">
                            <ul className="top-info text-center text-md-left">
                                <li><i className="fas fa-map-marker-alt"></i>
                                    <p className="info-text">
                                        Suite 213, Elyon Plaza, 182 1st Avenue Gwarinpa 900108, FCT Abuja, Nigeria
                                    </p>
                                </li>
                            </ul>
                        </div>

                        <div className="col-lg-4 col-md-4 top-social text-center text-md-right">
                            <ul className="list-unstyled">
                                <li>
                                    <a title="Facebook" href="https://web.facebook.com/profile.php?id=100085827203068">
                                        <span className="social-icon"><i className="fab fa-facebook-f"></i></span>
                                    </a>
                                    <a title="Instagram" href="https://www.instagram.com/realramadconsults/">
                                        <span className="social-icon"><i className="fab fa-instagram"></i></span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
            <header id="header" className="header-one">
                <div className="bg-white">
                    <div className="container">
                        <div className="logo-area">
                            <div className="row align-items-center">
                                <div className="logo col-lg-2 text-center text-lg-left mb-3 mb-md-5 mb-lg-0">
                                    <a className="d-block" href="/">
                                        <img loading="lazy" src={`${process.env.PUBLIC_URL}/assets/images/logo.png`} alt="logo" width = "50px" />
                                    </a>
                                </div>

                                <div className="col-lg-10 header-right">
                                    <ul className="top-info-box">
                                        <li>
                                            <div className="info-box">
                                                <div className="info-box-content">
                                                    <p className="info-box-title">Call Us</p>
                                                    <p className="info-box-subtitle"><a href="tel:+2348177663188">+234 817 766 3188</a></p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="info-box">
                                                <div className="info-box-content">
                                                    <p className="info-box-title">Email Us</p>
                                                    <p className="info-box-subtitle"><a href="mailto:info@ramadconsulting.com">info@ramadconsulting.com</a></p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="last">
                                            <div className="info-box last">
                                                <div className="info-box-content">
                                                    <p className="info-box-title">Operating Certificate</p>
                                                    <p className="info-box-subtitle">RC1668021</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="header-get-a-quote">
                                            <a className="btn btn-primary" href={`${process.env.PUBLIC_URL}/assets/docs/RAMAD_BROCHURE.pdf`}>Get Brochure</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="site-navigation">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <nav className="navbar navbar-expand-lg navbar-dark p-0">
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse" aria-controls="navbar-collapse" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>

                                    <div id="navbar-collapse" className="collapse navbar-collapse">
                                        <ul className="nav navbar-nav mr-auto">
                                            <li className="nav-item">
                                                <a href="/" className="nav-link">Home</a>
                                            </li>

                                            <li className="nav-item">
                                                <Link to="/about" className="nav-link">About</Link>
                                            </li>

                                            <li className="nav-item">
                                                <Link to="/services" className="nav-link">Services</Link>
                                            </li>

                                            <li className="nav-item">
                                                <Link to="/contact" className="nav-link">Contact</Link>
                                            </li>

                                            <li className="nav-item">
                                                <Link to="/gallery" className="nav-link">Gallery</Link>
                                            </li>

                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>

                        <div className="nav-search">
                            <span id="search"><i className="fa fa-search"></i></span>
                        </div>

                        <div className="search-block" style={{ display: 'none' }}>
                            <label htmlFor="search-field" className="w-100 mb-0">
                                <input type="text" className="form-control" id="search-field" placeholder="Search Ramad Website" />
                            </label>
                            <span className="search-close">&times;</span>
                        </div>
                    </div>

                </div>
            </header>

            <Outlet />

        </>
    );
}

export default Layout;
