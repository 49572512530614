
function Carousel() {

    return(
        <>
            <div className="banner-carousel banner-carousel-1 mb-0">
                <div className="banner-carousel-item" style={{backgroundImage: `url(` + process.env.PUBLIC_URL + `/assets/images/slider-main/bg_1.jpg)`, backgroundPosition: 'center'}}>
                    <div className="slider-content">
                        <div className="container h-100">
                        <div className="row align-items-center h-100">
                            <div className="col-md-12 text-center">
                                <h2 className="slide-title" data-animation-in="slideInLeft">Expert Years of excellence in</h2>
                                <h3 className="slide-sub-title" data-animation-in="slideInRight">Construction Industry</h3>
                                <p data-animation-in="slideInLeft" data-duration-in="1.2">
                                    <a href="/services" className="slider btn btn-primary">Our Services</a>
                                    <a href="/contact" className="slider btn btn-primary border">Contact Us</a>
                                </p>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>

                <div className="banner-carousel-item" style={{backgroundImage: `url(` + process.env.PUBLIC_URL + `/assets/images/slider-main/bg_2.jpg)`}}>
                    <div className="slider-content text-left">
                        <div className="container h-100">
                        <div className="row align-items-center h-100">
                            <div className="col-md-12">
                                <h2 className="slide-title-box" data-animation-in="slideInDown">World Class Service</h2>
                                <h3 className="slide-title" data-animation-in="fadeIn">When Service Matters</h3>
                                <h3 className="slide-sub-title" data-animation-in="slideInLeft">Your Choice is RAMAD Consulting</h3>
                                <p data-animation-in="slideInRight">
                                    <a href="/services" className="slider btn btn-primary border">Our Services</a>
                                </p>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>

                <div className="banner-carousel-item" style={{backgroundImage: `url(` + process.env.PUBLIC_URL + `/assets/images/slider-main/bg_3.jpg)`}}>
                    <div className="slider-content text-right">
                        <div className="container h-100">
                        <div className="row align-items-center h-100">
                            <div className="col-md-12">
                                <h2 className="slide-title" data-animation-in="slideInDown">Expert Engineers</h2>
                                <h3 className="slide-sub-title" data-animation-in="fadeIn">We believe sustainability</h3>
                                <p className="slider-description lead" data-animation-in="slideInRight">Trusted construction and engineering management with driven insights.</p>
                                <div data-animation-in="slideInLeft">
                                    <a href="/gallery" className="slider btn btn-primary" aria-label="contact-with-us">View Gallery</a>
                                    <a href="/about" className="slider btn btn-primary border" aria-label="learn-more-about-us">Learn more</a>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>

                <div className="banner-carousel-item" style={{backgroundImage: `url(` + process.env.PUBLIC_URL + `/assets/images/slider-main/bg_4.jpg)`}}>
                    <div className="slider-content text-right">
                        <div className="container h-100">
                        <div className="row align-items-center h-100">
                            <div className="col-md-12">
                                <h2 className="slide-title" data-animation-in="slideInDown">Master Architects</h2>
                                <h3 className="slide-sub-title" data-animation-in="fadeIn">Designing the Future with Integrity</h3>
                                <p className="slider-description lead" data-animation-in="slideInRight">Expert sustainable architecture and construction, delivering visionary solutions with precision.</p>
                                <div data-animation-in="slideInLeft">
                                    <a href="/gallery" className="slider btn btn-primary" aria-label="contact-with-us">View Gallery</a>
                                    <a href="/about" className="slider btn btn-primary border" aria-label="learn-more-about-us">Learn more</a>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                
            </div>

            <section className="call-to-action-box no-padding">
                <div className="container">
                    <div className="action-style-box">
                        <div className="row align-items-center">
                        <div className="col-md-8 text-center text-md-left">
                            <div className="call-to-action-text">
                                <h3 className="action-title">We understand your needs on construction</h3>
                            </div>
                        </div>
                        <div className="col-md-4 text-center text-md-right mt-3 mt-md-0">
                            <div className="call-to-action-btn">
                                <a className="btn btn-dark" href={`${process.env.PUBLIC_URL}/assets/docs/RAMAD_BROCHURE.pdf`}>Get Brochure</a>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Carousel;