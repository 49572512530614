import Footer from './Footer';
import Carousel from "./Carousel";
import { Link } from "react-router-dom";
import { useEffect } from 'react';
import { landmark } from './landmark';

function Home() {

    useEffect(() => {
        landmark()
    })
    
    return(
        <>
            <Carousel/>
            <section id="ts-features" className="ts-features">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="ts-intro">
                                <h2 className="into-title">About Us</h2>
                                <h3 className="into-sub-title">We deliver all of your Construction Services needs</h3>
                                <p>RAMAD CONSULTS is a full service construction company offering engineering solutions from start to finish. Our staff has been operating for years providing excellent workmanship and quality delivery.</p>
                                <p>
                                We undertake every project with carefull planning and analysis. Quality delivery is our goal. Tested quality is our target.
                                </p>
                            </div>

                            <div className="gap-20"></div>

                            <div className="row">
                                <div className="col-md-6">
                                <div className="ts-service-box">
                                    <span className="ts-service-icon">
                                        <i className="fas fa-trophy"></i>
                                    </span>
                                    <div className="ts-service-box-content">
                                        <h3 className="service-box-title">We have Reputation for Excellence</h3>
                                    </div>
                                </div>
                                </div>

                                <div className="col-md-6">
                                <div className="ts-service-box">
                                    <span className="ts-service-icon">
                                        <i className="fas fa-handshake"></i>
                                    </span>
                                    <div className="ts-service-box-content">
                                        <h3 className="service-box-title">We Build Partnerships</h3>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                <div className="ts-service-box">
                                    <span className="ts-service-icon">
                                        <i className="fas fa-thumbs-up"></i>
                                    </span>
                                    <div className="ts-service-box-content">
                                        <h3 className="service-box-title">Guided by Commitment</h3>
                                    </div>
                                </div>
                                </div>

                                <div className="col-md-6">
                                <div className="ts-service-box">
                                    <span className="ts-service-icon">
                                        <i className="fas fa-users"></i>
                                    </span>
                                    <div className="ts-service-box-content">
                                        <h3 className="service-box-title">A Team of Professionals</h3>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 mt-4 mt-lg-0">
                            <h3 className="into-sub-title">Our Values</h3>
                            <p>Our reputation for excellence is based on our core philosophy and drive for satisfaction of our clients no matter the scale or level of the project. We are always commited to deliver highest quality because we upload global professional practices.</p>

                            <div className="accordion accordion-group" id="our-values-accordion">

                                <div className="card">
                                    <div className="card-header p-0 bg-transparent" id="headingTwo">
                                        <h2 className="mb-0">
                                            <button className="btn btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                                Mission
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseTwo" className="collapse show" aria-labelledby="headingTwo" data-parent="#our-values-accordion">
                                        <div className="card-body">
                                            Ramad Consultants Ltd., aims to provide excellent, quality and cost effective engineering designs and construction services that meet global standards and make a difference especially in terms of Safety, Quality and Durability built on foundations of Reliability, Integrity and Trust.
                                        </div>
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="card-header p-0 bg-transparent" id="headingOne">
                                        <h2 className="mb-0">
                                            <button className="btn btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                            Vision
                                            </button>
                                        </h2>
                                    </div>
                                
                                    <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#our-values-accordion">
                                        <div className="card-body">
                                            Ramad Consultants Ltd. was formed with a vision of attaining leadership in the provision of quality standard engineering designs, project management and building construction services.
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="card">
                                    <div className="card-header p-0 bg-transparent" id="headingThree">
                                        <h2 className="mb-0">
                                            <button className="btn btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                Service Scope
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#our-values-accordion">
                                        <div className="card-body">
                                            Our clients include government and non-governmental agencies, corporate organizations, real estate developers, construction companies, architectural firms, banks and private individuals. Generally our clients encompass people with a need for quality and excellence in job delivery.
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="row justify-content-center mt-5 text-center">

                            <div className="col-lg-4 col-sm-12 ts-facts mt-3">
                                <div className="p-5 shadow-sm">
                                    <div className="ts-facts-img">
                                        <i className="fas fa-camera fa-3x text-danger"></i>
                                    </div>
                                    <div className="ts-facts-content mt-4">
                                        <h3 className="ts-facts-nm text-dark">Vision</h3>
                                        <p className="ts-facts-title text-dark">
                                            <span className='small'>
                                            The vision of Ramad Consultants Ltd. is to set a new standard of engineering service delivery that guarantees public safety, good quality and cost effective solution through creativity, innovation and up to date design approach.

                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-sm-12 ts-facts mt-3">
                                <div className="p-5 shadow-sm">
                                    <div className="ts-facts-img">
                                        <i className="fas fa-paper-plane fa-3x text-danger"></i>
                                    </div>
                                    <div className="ts-facts-content mt-4">
                                        <h3 className="ts-facts-nm text-dark">Mission</h3>
                                        <p className="ts-facts-title text-dark">
                                            <span className='small'>
                                            We aim to provide excellent and efficient civil engineering design services that would meet global standards and make a difference especially in terms of Safety, Quality and Cost built on foundations of Credibility, Integrity and Trust.
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-sm-12 ts-facts mt-3 h-100">
                                <div className="p-5 shadow-sm">
                                    <div className="ts-facts-img">
                                        <i className="fas fa-globe fa-3x text-danger"></i>
                                    </div>
                                    <div className="ts-facts-content mt-4">
                                        <h3 className="ts-facts-nm text-dark">Clientelle</h3>
                                        <p className="ts-facts-title text-dark">
                                            <span className='small'>
                                            Our clients include government/non-governmental agencies, corporate organizations, real estate developers, construction companies, architectural firms, banks and others. We serve those with a need for quality and excellence.

                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6 ts-facts mt-5 mt-md-0 d-none">
                                <div className="ts-facts-img">
                                    <img loading="lazy" src="../assets/images/icon-image/fact4.png" alt="facts-img"/>
                                </div>
                                <div className="ts-facts-content">
                                    <h2 className="ts-facts-num"><span className="counterUp" data-count="44">0</span></h2>
                                    <h3 className="ts-facts-title">Countries Experience</h3>
                                </div>
                            </div>

                        </div>
                </div>
            </section>

            <section id="facts" className="facts-area dark-bg">
                <div className="container">
                    <div className="facts-wrapper">
                        <div className="row">
                            <div className="col-md-3 col-sm-6 ts-facts">
                                <div className="ts-facts-img">
                                    <img loading="lazy" src={process.env.PUBLIC_URL + `/assets/images/icon-image/fact1.png`} alt="facts-img"/>
                                </div>
                                <div className="ts-facts-content">
                                    <h2 className="ts-facts-num"><span className="counterUp" data-count="200">0</span>+</h2>
                                    <h3 className="ts-facts-title">Projects</h3>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6 ts-facts mt-5 mt-sm-0">
                                <div className="ts-facts-img">
                                    <img loading="lazy" src={`${process.env.PUBLIC_URL}/assets/images/icon-image/service-icon4.png`} alt="facts-img"/>
                                </div>
                                <div className="ts-facts-content">
                                    <h2 className="ts-facts-num"><span className="counterUp" data-count="647">0</span>+</h2>
                                    <h3 className="ts-facts-title">Design Plans</h3>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6 ts-facts mt-5 mt-md-0">
                                <div className="ts-facts-img">
                                    <img loading="lazy" src={`${process.env.PUBLIC_URL}/assets/images/icon-image/fact3.png`} alt="facts-img"/>
                                </div>
                                <div className="ts-facts-content">
                                    <h2 className="ts-facts-num"><span className="counterUp" data-count="99.9">0</span>%</h2>
                                    <h3 className="ts-facts-title">Work Rate</h3>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6 ts-facts mt-5 mt-md-0">
                                <div className="ts-facts-img">
                                    <img loading="lazy" src={`${process.env.PUBLIC_URL}/assets/images/icon-image/fact4.png`} alt="facts-img"/>
                                </div>
                                <div className="ts-facts-content">
                                    <h2 className="ts-facts-num"><span className="counterUp" data-count="44">0</span>+</h2>
                                    <h3 className="ts-facts-title">Countries Experience</h3>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section id="ts-service-area" className="ts-service-area pb-0">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-12">
                        <h2 className="section-title">We Are Specialists In</h2>
                        <h3 className="section-sub-title">What We Do</h3>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-md-12">
                            <div className="ts-service-box d-flex">
                                <div className="ts-service-box-img">
                                    <img loading="lazy" src={`${process.env.PUBLIC_URL}/assets/images/icon-image/service-icon1.png`} alt="service-icon"/>
                                </div>
                                <div className="ts-service-box-info">
                                    <h3 className="service-box-title"><a href="#">Home Construction</a></h3>
                                    <p>
                                        RAMAD has worked on various projects on humanitarian scale with good reports of excellence.
                                    </p>
                                </div>
                            </div>

                            <div className="ts-service-box d-flex">
                                <div className="ts-service-box-img">
                                    <img loading="lazy" src={`${process.env.PUBLIC_URL}/assets/images/icon-image/service-icon2.png`} alt="service-icon"/>
                                </div>
                                <div className="ts-service-box-info">
                                    <h3 className="service-box-title"><a href="#">Pre-construction Estimates</a></h3>
                                    <p>We help you cut off unnecessary expenses that eat up your budget.</p>
                                </div>
                            </div>

                            <div className="ts-service-box d-flex">
                                <div className="ts-service-box-img">
                                    <img loading="lazy" src={`${process.env.PUBLIC_URL}/assets/images/icon-image/service-icon3.png`} alt="service-icon"/>
                                </div>
                                <div className="ts-service-box-info">
                                    <h3 className="service-box-title"><a href="#">Interior Design</a></h3>
                                    <p>Feel the warmth of interior finishing and peculiar selections of choice.</p>
                                </div>
                            </div>

                        </div>

                        <div className="col-lg-4 text-center">&nbsp;
                            <img loading="lazy" className="img-fluid service_center_image" src={`${process.env.PUBLIC_URL}/assets/images/services/service-center.jpg`} alt="service-avater-image"/>
                        </div>

                        <div className="col-lg-4 col-md-12 mt-5 mt-lg-0 mb-4 mb-lg-0">
                            <div className="ts-service-box d-flex">
                                <div className="ts-service-box-img">
                                    <img loading="lazy" src={`${process.env.PUBLIC_URL}/assets/images/icon-image/service-icon4.png`} alt="service-icon"/>
                                </div>
                                <div className="ts-service-box-info">
                                    <h3 className="service-box-title"><a href="#">Architectural Plan</a></h3>
                                    <p>We offer architectural designs to help you plan and build your home.</p>
                                </div>
                            </div>

                            <div className="ts-service-box d-flex">
                                <div className="ts-service-box-img">
                                    <img loading="lazy" src={`${process.env.PUBLIC_URL}/assets/images/icon-image/service-icon5.png`} alt="service-icon"/>
                                </div>
                                <div className="ts-service-box-info">
                                    <h3 className="service-box-title"><a href="#">Design and Build</a></h3>
                                    <p>We design. We build. We deliver. We are experts in what we choose to do.</p>
                                </div>
                            </div>

                            <div className="ts-service-box d-flex">
                                <div className="ts-service-box-img">
                                    <img loading="lazy" src={`${process.env.PUBLIC_URL}/assets/images/icon-image/service-icon6.png`} alt="service-icon"/>
                                </div>
                                <div className="ts-service-box-info">
                                    <h3 className="service-box-title"><a href="#">Safety Management</a></h3>
                                    <p>We harness the most advanced and well researched safety management policies to guarantee returns.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className="subscribe no-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="subscribe-call-to-acton">
                                <h3>We can Help!</h3>
                                <h4>+234 817 766 3188</h4>
                            </div>
                        </div>

                        <div className="col-md-8">
                            <div className="ts-newsletter row align-items-center">
                                <div className="col-md-5 newsletter-introtext">
                                    <h4 className="text-white mb-0">Request Brochure</h4>
                                    <p className="text-white">Read our brochure to know more.</p>
                                </div>

                                <div className="col-md-7 newsletter-form">
                                    <form action="#" method="post">
                                        <div className="form-group">
                                        <label htmlFor="newsletter-email" className="content-hidden">Your Email</label>
                                        <input type="email" name="email" id="newsletter-email" className="form-control form-control-lg" placeholder="Enter your your email and hit enter" autoComplete="off"/>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section id="news" className="news">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-12">
                            <h2 className="section-title">Works of Excellence</h2>
                            <h3 className="section-sub-title">Accomplished Projects</h3>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-lg-4 col-md-12 mb-4">
                            <div className="latest-post border">
                                <div className="latest-post-media">
                                    <div className='bg-danger p-0' style = {{height: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: `url(${process.env.PUBLIC_URL}/ramad_server_pictures/gallery/hypercity.jpg)`}}></div>
                                </div>
                                <div className="post-body p-3">
                                    <h4 className="post-title">
                                        <a href="/gallery" className="d-inline-block">Port Harcourt Hypercity Mall</a>
                                    </h4>
                                    <div className="latest-post-meta">
                                        <span className="post-item-date small mb-2 d-inline-block">
                                            <span className='badge badge-danger rounded-0 p-1'>Architecture</span>
                                        </span>
                                        <p className="post-item-date mb-0" style = {{lineHeight: '20px', textTransform: 'none !important'}}>
                                            Front View: Port Harcourt Hypercity Mall designed with landscape impression.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12 mb-4">
                            <div className="latest-post border">
                                <div className="latest-post-media">
                                    <div className='bg-danger p-0' style = {{height: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: `url(${process.env.PUBLIC_URL}/ramad_server_pictures/gallery/port_harcourt_building.jpg)`}}></div>
                                </div>
                                <div className="post-body p-3">
                                    <h4 className="post-title">
                                        <a href="/gallery" className="d-inline-block">Port Harcourt Garden City</a>
                                    </h4>
                                    <div className="latest-post-meta">
                                        <span className="post-item-date small mb-2 d-inline-block">
                                            <span className='badge badge-danger rounded-0 p-1'>Design & Construction</span>
                                        </span>
                                        <p className="post-item-date mb-0" style = {{lineHeight: '20px', textTransform: 'none !important'}}>
                                            Outer View: Shoprite Mall, Port Harcourt Garden City.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12 mb-4">
                            <div className="latest-post border">
                                <div className="latest-post-media">
                                    <div className='bg-danger p-0' style = {{height: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: `url(${process.env.PUBLIC_URL}/ramad_server_pictures/gallery/port_harcourt_building_2.jpg)`}}></div>
                                </div>
                                <div className="post-body p-3">
                                    <h4 className="post-title">
                                        <a href="/gallery" className="d-inline-block">Port Harcourt Garden City</a>
                                    </h4>
                                    <div className="latest-post-meta">
                                        <span className="post-item-date small mb-2 d-inline-block">
                                            <span className='badge badge-danger rounded-0 p-1'>Design & Construction</span>
                                        </span>
                                        <p className="post-item-date mb-0" style = {{lineHeight: '20px', textTransform: 'none !important'}}>
                                            Inner View: Shoprite Mall, Port Harcourt Garden City.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12 mb-4">
                            <div className="latest-post border">
                                <div className="latest-post-media">
                                    <div className='bg-danger p-0' style = {{height: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: `url(${process.env.PUBLIC_URL}/ramad_server_pictures/gallery/banquet_hall_2.jpg)`}}></div>
                                </div>
                                <div className="post-body p-3">
                                    <h4 className="post-title">
                                        <a href="/gallery" className="d-inline-block">Banquet Hall Building</a>
                                    </h4>
                                    <div className="latest-post-meta">
                                        <span className="post-item-date small mb-2 d-inline-block">
                                            <span className='badge badge-danger rounded-0 p-1'>Architecture</span>
                                        </span>
                                        <p className="post-item-date mb-0" style = {{lineHeight: '20px', textTransform: 'none !important'}}>
                                            Outer View: Banquet Hall @ Port-Harcourt designed by RAMAD Consultants Ltd.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-lg-6 col-md-6 mb-4">
                            <div className="latest-post border">
                                <div className="latest-post-media">
                                    <div className='bg-danger p-0' style = {{height: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: `url(${process.env.PUBLIC_URL}/ramad_server_pictures/gallery/banquet_hall_3.jpg)`}}></div>
                                </div>
                                <div className="post-body p-3">
                                    <h4 className="post-title">
                                        <a href="/gallery" className="d-inline-block">Banquet Hall Building</a>
                                    </h4>
                                    <div className="latest-post-meta">
                                        <span className="post-item-date small mb-2 d-inline-block">
                                            <span className='badge badge-danger rounded-0 p-1'>Design & Construction</span>
                                        </span>
                                        <p className="post-item-date mb-0" style = {{lineHeight: '20px', textTransform: 'none !important'}}>
                                            Outer View: Banquet Hall @ Port-Harcourt constructed by RAMAD Consultants Ltd.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 mb-4">
                            <div className="latest-post border">
                                <div className="latest-post-media">
                                    <div className='bg-danger p-0' style = {{height: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: `url(${process.env.PUBLIC_URL}/ramad_server_pictures/gallery/banquet_hall.jpg)`}}></div>
                                </div>
                                <div className="post-body p-3">
                                    <h4 className="post-title">
                                        <a href="/gallery" className="d-inline-block">Banquet Hall Building</a>
                                    </h4>
                                    <div className="latest-post-meta">
                                        <span className="post-item-date small mb-2 d-inline-block">
                                            <span className='badge badge-danger rounded-0 p-1'>Design & Construction</span>
                                        </span>
                                        <p className="post-item-date mb-0" style = {{lineHeight: '20px', textTransform: 'none !important'}}>
                                            Outer View: Banquet Hall @ Port-Harcourt constructed by RAMAD Consultants Ltd.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12 mb-4">
                            <div className="latest-post border">
                                <div className="latest-post-media">
                                    <div className='bg-danger p-0' style = {{height: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: `url(${process.env.PUBLIC_URL}/ramad_server_pictures/gallery/banquet_hall_1.jpg)`}}></div>
                                </div>
                                <div className="post-body p-3">
                                    <h4 className="post-title">
                                        <a href="/gallery" className="d-inline-block">Banquet Hall Building</a>
                                    </h4>
                                    <div className="latest-post-meta">
                                        <span className="post-item-date small mb-2 d-inline-block">
                                            <span className='badge badge-danger rounded-0 p-1'>Interior Finishing</span>
                                        </span>
                                        <p className="post-item-date mb-0" style = {{lineHeight: '20px', textTransform: 'none !important'}}>
                                            Outer View: Banquet Hall @ Port-Harcourt constructed by RAMAD Consultants Ltd.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="general-btn text-center mt-4">
                        <Link to="/gallery" className = "btn btn-primary px-4">See More <i className='fas fa-caret-right ml-3'></i></Link>
                    </div>

                </div>
            </section>

            <Footer/>
        </>
    )
}

export default Home;