import { Link } from "react-router-dom";

function Footer() {
    return(
        <footer id="footer" className="footer bg-overlay">
          <div className="footer-main">
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-lg-4 col-md-6 footer-widget footer-about">
                  <img loading="lazy" width="200px" className="footer-logo" src={`${process.env.PUBLIC_URL}/ramad_server_pictures/footer-logo.png`} alt="Ramad Logo"/>
                  <p>RAMAD CONSULTS is a full service construction company offering engineering solutions from start to finish. We have been operating as leading experts for years providing excellent workmanship and quality delivery.</p>
                  <div className="footer-social">
                    <ul>

                      <li>
                        <a target="_BLANK" rel="noreferrer" href="https://web.facebook.com/profile.php?id=100085827203068" aria-label="Facebook">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a target="_BLANK" rel="noreferrer" href="https://www.instagram.com/realramadconsults/" aria-label="Instagram">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>

                    </ul>
                  </div>
                </div>

                <div className="col-lg-5 col-md-6 footer-widget mt-5 mt-md-0">
                  <h3 className="widget-title">Working Hours</h3>
                  <div className="working-hours">
                    We are available to work every week, every day excluding weekends and major holidays. Contact us if you have an enquiry, with our
                    Hotline and Contact form.
                    <br/><br/> Monday - Friday: <span className="text-right">09:00 - 17:00 </span>
                    <br/> Saturday: <span className="text-right">12:00 - 17:00</span>
                    <br/> Holidays: <span className="text-right">10:00 - 02:00</span>
                  </div>
                </div>

                <div className="col-lg-2 col-md-6 mt-5 mt-lg-0 footer-widget">
                  <h3 className="widget-title">Links</h3>
                  <ul className="list-arrow">
                    <li><a href="/">Home</a></li>
                    <li><Link to="/about">About</Link></li>
                    <li><Link to="/services">Services</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                    <li><Link to="/gallery">Gallery</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="copyright text-white">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="copyright-info text-center text-md-left">
                    <span>Copyright &copy; {(new Date().getFullYear()) }, Designed &amp; Developed by <a href="https://qodesquare.com">Bamsquared Tech. Consults</a></span>
                  </div>
                </div>
              </div>

              <div id="back-to-top" data-spy="affix" data-offset-top="10" className="back-to-top position-fixed">
                <button className="btn btn-primary" title="Back to Top">
                  <i className="fa fa-angle-double-up"></i>
                </button>
              </div>

            </div>
          </div>
        </footer>
    );
}

export default Footer;