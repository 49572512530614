import Footer from './Footer';
import React, { useEffect } from 'react';
import { landmark } from './landmark';

function Contact() {

    const myStyle = {
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/banner/contact_banner_1.jpg)`,
        height: '450px',
        backgroundPosition: 'top'
    };

    const overlay = {
        width: '100%',
        background: 'rgba(0, 0, 0, 0.6)',
        position: 'absolute'
    }

    useEffect(() => {

        landmark()

    })

    return (
        <>
            <div id="banner-area" className="banner-area" style={myStyle}>
                <div style={{ ...overlay, height: '100%' }}></div>
                <div className="banner-text">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="banner-heading">
                                    <h1 className="banner-title">Contact</h1>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb justify-content-center">
                                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">Contact</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section id="main-container" className="main-container">
                <div className="container">

                    <div className="row text-center">
                        <div className="col-12">
                            <h2 className="section-title">Reach us today</h2>
                            <h3 className="section-sub-title">Find Our Location</h3>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4 mb-3">
                            <div className="ts-service-box-bg text-center h-100">
                                <span className="ts-service-icon icon-round">
                                    <i className="fas fa-map-marker-alt mr-0"></i>
                                </span>
                                <div className="ts-service-box-content">
                                    <h4>Visit Our Office</h4>
                                    <div className='d-flex flex-row'>
                                        <div>ABUJA:</div>
                                        <div>Plot 375 Olikoye R Kuti Close, Katampe Extension</div>
                                    </div>
                                    <div className='d-flex flex-row'>
                                        <div className='mr-3'>LAGOS:</div>
                                        <div>
                                            No 56, Ijaiye Road, Ogba, Ikeja
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="col-md-4 mb-3">
                            <div className="ts-service-box-bg text-center h-100">
                                <span className="ts-service-icon icon-round">
                                    <i className="fa fa-envelope mr-0"></i>
                                </span>
                                <div className="ts-service-box-content">
                                    <h4>Email Us</h4>
                                    <p>info@ramadconsulting.com</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="ts-service-box-bg text-center h-100">
                                <span className="ts-service-icon icon-round">
                                    <i className="fa fa-phone-square mr-0"></i>
                                </span>
                                <div className="ts-service-box-content">
                                    <h4>Call Us</h4>
                                    <p>+234 817 766 3188</p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="gap-60"></div>

                    <div className="google-map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8068168.336501443!2d-1.8137121249999824!3d9.106024800000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104e75670a053da5%3A0x550baa48ce7acf3f!2sElyon%20Plaza!5e0!3m2!1sen!2sng!4v1723399499941!5m2!1sen!2sng" width="100%" height="450" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>

                    <div className="gap-40"></div>

                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="column-title">Let's work with you. Talk to us!</h3>
                            <form id="contact-form" action="#" method="post" role="form">
                                <div className="error-container"></div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Name</label>
                                            <input className="form-control form-control-name" name="name" id="name" placeholder="John" type="text" required />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input className="form-control form-control-email" name="email" id="email" placeholder="Jolly" type="email"
                                                required />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Subject</label>
                                            <input className="form-control form-control-subject" name="subject" id="subject" placeholder="Project Enquiry" required />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Message</label>
                                    <textarea className="form-control form-control-message" name="message" id="message" placeholder="Hello RAMAD, we need your services." rows="10"
                                        required></textarea>
                                </div>
                                <div className="text-right"><br />
                                    <button className="btn btn-primary solid blank" type="submit">Send Message</button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </section>

            <div className="chose_us_area chose_bg_1 py-5" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/banner/chose_banner.jpg)`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="container py-5">
                    <div className="row justify-content-end py-5">
                        <div className="col-lg-6 col-md-8">
                            <div style={{ background: `rgba(255, 255, 255, 0.7)` }} className="p-5 chose_info">
                                <h2 className="font-weight-bold">Why Choose Us?</h2>
                                <h3 style={{ textTransform: `none` }}>
                                    “We understand the dynamics of design services that you may need and recognize the unique opportunity of providing architecture and engineering design services to your company.
                                </h3>
                                <p>We believe that the construction industry in Nigeria is in its growing stage and we are uniquely positioned to successfully partner with you in providing cost effective engineering solutions in a competent, reliable and professional manner.</p>
                                <a href="/services" className="slider ml-0 btn btn-primary">Our Services</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Contact;